import '../scss/style.scss';
import FastClick from 'fastclick';
import select from 'dom-select';
import { classy as cl } from './helpers/classy';
import SmoothScroll from 'smooth-scroll';
import Swiper from 'swiper/swiper-bundle.min';
import Spoilers from "./helpers/spoilers";
import Spoilers2 from "./helpers/spoilers2";
import throttle from 'lodash.throttle';
import axios from 'axios';
const autocomplete = require('autocomplete.js');
import LazyLoad from 'vanilla-lazyload'
import findlastindex from 'lodash.findlastindex'
//import Stickyfill from 'stickyfilljs';
// import * as bodyScrollLock from 'body-scroll-lock';
import Collapse from 'bootstrap.native/dist/components/collapse-native.esm.js';
//import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm.js';
// import Tooltip from './vendor/tooltip-native.esm.js';


(function (doc, win) {
  var app = { activeModal: false, lazyLoadInstance: null, mobileMenuCollapseInit: null };

  function activenav() {
    var current = window.location.protocol + "//" + window.location.host + "/" + location.pathname.split("/")[1];
    select.all('.header a, .nav a').forEach(function(nav) {
      if (nav.href === current) cl(nav).addCl('active');
      else cl(nav).rmCl('active');
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600
      });
    }
  }

  function inputs() {
    select.all('.input').forEach(function(input) {
      if (input.value !== '') input.addCl('filled');
      input.addEventListener('focus', function() {
        input.classList.add('filled');
      });
      input.addEventListener('focusout', function() {
        if (input.value === '') input.classList.remove('filled');
      });
    });
  }

  // function stickyEls() {
  //   Stickyfill.removeAll();
  //   const stickys = select.all('.sticky');
  //   if (stickys.length) Stickyfill.add(stickys);
  // }

  function checkForLatVisible(swiper) {
    const elInd = findlastindex(swiper.slides, s => s.classList.contains('swiper-slide-visible'))
    const lastVisible = swiper.slides[elInd]
    if (lastVisible && elInd > -1 && !swiper.isEnd) {
      const lastVisibleRect = lastVisible.getBoundingClientRect()
      const wrapperRight = swiper.$el[0].getBoundingClientRect().right
      if ((lastVisibleRect.right - wrapperRight) > 0) {
        cl(lastVisible).rmCl('swiper-slide-visible')
      }
    }

    if(!swiper.isBeginning)
    {
      swiper.slides.forEach(function (sw) {

        const lastVisibleRect = sw.getBoundingClientRect()
        const wrapperLeft = swiper.$el[0].getBoundingClientRect().left
        if (lastVisibleRect.left  < wrapperLeft ) {
          cl(sw).rmCl('swiper-slide-visible')
        }
      });
    }
  }

  function getWidth() {
    if (self.innerWidth) {
      return self.innerWidth;
    }
  
    if (document.documentElement && document.documentElement.clientWidth) {
      return document.documentElement.clientWidth;
    }
  
    if (document.body) {
      return document.body.clientWidth;
    }
  }

  var swipperData;
  var runningWidth = 0;
  var sliderHasRun = false;
  var sliderIsRunning = false;
  var lastMax = 0;
  function setchairSwipper(hasSwipper){
    if(hasSwipper)
    {

      // if( sliderIsRunning ) {
      //   console.log('already running - don`\t re-run dufas');
      //   return;
      // }


      //another 50 for spacing...
      var newRunningWidth = getWidth() - 50;
      var swipperWrapper = select('.swiper-wrapper');
      var swipperSlide = select.all('.swiper-slide');
      var currentSwipperWrapperMax = parseInt(swipperSlide[0].dataset.maxWidth);
      var newMax = 1020;
      

      //same width being requested, ignore.
      if(newRunningWidth == runningWidth && sliderHasRun) return
      

      if( newRunningWidth < 1100 ) newMax  = 1000;
      if( newRunningWidth < 1000 ) newMax  = 900;
      if( newRunningWidth < 950 ) newMax  = 800;
      if( newRunningWidth < 800 ) newMax  = 600;
      if( newRunningWidth < 650 ) newMax  = 500;
      if( newRunningWidth < 600 ) newMax  = 400;
      if( newRunningWidth < 350 ) newMax  = 1;

      //console.log(lastMax, newMax, (newMax == lastMax) )


      //same calc - don't re-run
      if(lastMax == newMax && sliderHasRun) return;

      // console.log(newRunningWidth,newMax,currentSwipperWrapperMax);
      if( newMax == currentSwipperWrapperMax && sliderHasRun === false) 
      { 
        //console.log('line 144'); 
        swipers();
        sliderHasRun = true;
        return;
      }
      else
      {
        sliderIsRunning = true;
        axios.post('/api/slider', {
          width: newMax,
          id: hasSwipper.dataset.rangeId
        })
        .then(function (response) {

          lastMax = newMax;
          // console.log(newRunningWidth,runningWidth);
          // console.log(response);
          setTimeout(() => {sliderIsRunning = false;},500)
          
          if(response.status == 200 && response.data != '')
          {
             sliderHasRun = true;
              swipperWrapper.innerHTML = response.data;
              //console.log('line 167'); 
              swipers();
              app.lazyLoadInstance.update()
              app.spoilers = new Spoilers();
              app.spoilers2 = new Spoilers2('.chairs-list-section, .block-collapsibles');
            
          }
        })
      }
    }
  }
  
  const chairSwipper = function () {
    const hasSwipper = select('.range-swiper-wrap');
    this.resize = function () {
      setchairSwipper(hasSwipper)
    }
    //don't automatically run.
    this.resize()
  }

  var swipersAll = [];
  function swipers() {
    //console.log('swippwers called')
    swipersAll.forEach(function (sw) {
      sw.destroy();
    });
    swipersAll = [];

    select.all('.range-swiper').forEach(function (el) {

      var sw = new Swiper(el, {
        speed: 600,
        noSwiping:true,
        noSwipingClass:'chairs-swiper-image',
        watchSlidesVisibility: true,
        slidesPerView: 1,
        centeredSlides: false,
        slidesPerGroup: 1,
        shortSwipes: false,
        navigation: {
          nextEl: '.sw-next',
          prevEl: '.sw-prev',
        },
        on: {
          transitionStart: function (ev) {
            // logic - if we have an open tab and we slide - it will always need to close as the chair will now be hidden
            app.spoilers.closeAll();
          //  checkForLatVisible(this)
          },
          // transitionEnd: function () {
          //   checkForLatVisible(this)
          //   setTimeout(() => {
          //     checkForLatVisible(this)
          //   }, 1000)
          // },
          init: function () {
            // checkForLatVisible(this)
            const hash = window.location.hash;
            let activeSlide = false;
            let activeSlideOpen = false;
            let containerWidth = select('.swiper-wrapper').offsetWidth;

            //select('.swiper-wrapper').style.opacity = 0;
            //swiper-wrapper
            // console.log('containerWidth: '+ containerWidth);
            if(typeof this.slides != 'undefined' && this.slides.length){
            
              //select('.range-swiper-wrap').style.height =  this.slides[0].getElementsByClassName("chair-item")[0].offsetHeight + "px";
            
              //fix for bug 413 where the top of the chair is being chopped off.
              select('.range-swiper').style.height = null;
            }

  
            setTimeout(() => {
                select('.swiper-wrapper').style.opacity = 1;

                // select.all('.range-swiper .swiper-slide').forEach((slide) => {
                //   slide.style.opacity = 0;
                // })

                //select('.product-spoilers.no-opacity').style.opacity = 1;
                cl('.no-opacity').rmCl('no-opacity');


                select('.range-swiper .swiper-slide-active').style.opacity = 1;

            },800)

            setTimeout(() => {
              if(typeof this.slides != 'undefined' && this.slides.length)
              {
                this.slides.forEach((swipper,index) => {

                  let calWidth = 0;
                  let chairs = swipper.getElementsByClassName("chair-item");
                  let chairLen = chairs.length;

                  /* get the total width of the images/chairs in this slide so we can get the margin required to add to the first chair so we can cente the chairs. */
                  for(var i = 0; i < chairLen; i++)
                  {

                    if(i == 0){
                      activeSlide = false;
                      activeSlideOpen = false;
                      // if(!hash){
                      //   activeSlide = index;
                      //   activeSlideOpen = chairs[i];
                      // }
                    }



                    /* We've got an active chair - slide to this swipper */
                    if(hash && hash == '#'+chairs[i].dataset.spoiler) activeSlide = index;
                    
                    /* active chair and we want the info box open */
                    if(hash && hash == '#'+chairs[i].dataset.spoiler+'-open') {
                      activeSlide = index;
                      activeSlideOpen = chairs[i];
                    }


                    //console.log(index,i,activeSlide, activeSlideOpen)

                    
                    calWidth += parseInt(chairs[i].dataset.width);


                    ///console.log('dataset.width '+ chairs[i].dataset.width, 'offsetWidth '+ chairs[i].offsetWidth, 'calWidth '+ calWidth,'slide  '+ ( index + 1), 'child '+ (i + 1));

                    if(i == (chairLen - 1))
                    {
                      //last chair - calc the padding & add to the first chair.
                      let padding  = (containerWidth - calWidth) / 2;


                      //console.log('height: .swiper-wrapper',select('.swiper-wrapper').offsetHeight);
                      //console.log('height: .range-swiper',select('.range-swiper').offsetHeight);

                      // so we don't get a flicker
                      //console.log('setting height now')
                      select('.range-swiper-wrap').style.height = select('.range-swiper').offsetHeight + "px";


                      
                      

                      swipper.getElementsByClassName("chair-item")[0].style.marginLeft = padding + "px";
                      swipper.getElementsByClassName("chair-item")[i].style.marginRight = padding + "px";

                      if (activeSlide !== false ) {
                        this.slideTo(activeSlide)
                        if (activeSlideOpen != false && activeSlideOpen.classList.contains('active') == false ) {
                          // setTimeout(() => {
                             // console.log('open chair please');
                              activeSlideOpen.click();
                          // },500)
                        }
                     }
                     
                    }


                    if(chairLen == 1) 
                    {
                       //need to force the tab open here!!??
                       //See bug #259
                    }
                  }
                })
              }
              // initTooltip();
            }, 600)
          }
        }
      });
      swipersAll.push(sw);
    })

    select.all('.swiper-regular').forEach(sw => {
      let speed = Number(sw.dataset.speed) || 1000;
      let autoplay = Number(sw.dataset.autoplay) || false;

      const sr = new Swiper(sw, {
        speed: speed,
        autoplay: autoplay ? {
          delay: autoplay,
        } : false,
        loop: autoplay,
        pagination: {
          el: select('.swiper-pagination', sw),
          type: 'bullets'
        },
        navigation: {
          nextEl: select('.swiper-navs .control-right', sw),
          prevEl: select('.swiper-navs .control-left', sw),
        },
        on: {
          init() {
            app.lazyLoadInstance.update()
          }
        }
      })
      swipersAll.push(sr);
    })
  }

  var modals = function() {
    var modalClicks = select.all('[data-modal]');
    var modalsEls = select.all('.modal');
    var modals = {};

    function close(m, click) {
      cl(m.el).rmCl('active');
      if (click) cl(click).rmCl('active');
      else cl(`[data-modal="${m.id}"]`).rmCl('active');
      m.open = false;
    }

    modalsEls.forEach(m => {
      if (m.id) {
        modals[m.id] = {
          el: m,
          open: false,
          id: m.id,
        };

        var cross = select('.close', m);
        if (cross) cross.addEventListener('click', () => {
          close(modals[m.id]);
        })
      }
    });

    modalClicks.forEach(click => {
      var id = click.dataset.modal;
      click.addEventListener('click', () => {
        var modal = modals[id];

        if (modal) {
          if (modal.open) close(modal, click);
          else {
            cl(modal.el).addCl('active');
            cl(click).addCl('active');
            modal.open = true;
          }
        }

        Object.values(modals).forEach(m => {
          if (m.id !== id) close(m)
        })
      })
    });

    this.close = (target) => {
      Object.values(modals).forEach(m => {
        if (target) {
          var click = target.closest('[data-modal]');
          var modal = target.closest('.modal');
          if (!click && m.el !== modal) close(m)
        } else close(m);
      });
    }
  };

  function highlight(input, text) {
    // console.log(input, text);
    const re = new RegExp(input.split(' ').join("|"),"gi");
    return text.replace(re, function(matched) {
      return `<span class="highlight">${matched}</span>`;
    });
  }

  function setAutocompletes() {
    autocomplete('.search-form input', {
        hint: false,
        debug: true,
        minLength: 3
      },
      [{
        source: (input, callback) => {
          axios('/api/search.json?search='+input).then(({ data }) => {
            if (data.length) data.forEach(r => (r._input = input))
            callback(data)
          });
        },
        displayKey: 'description',
        name: 'search',
        debounce: 100,
        templates: {
          suggestion: function (suggestion) {
            // console.log(suggestion);
            const regExp = new RegExp('(' + suggestion._input + ')', 'giu');
            const text = suggestion.title.replace(regExp, '<span class="highlight">$1</span>')
            return text;
          },
          empty: function({ query }) {
            return `<div class="no-results">No results for: <span class="is-bold text-red">${query}</span></div>`
          }
        }
    }]).on('autocomplete:selected', function(event, suggestion) {
       window.location = suggestion.url;
      cl('.search-wrap').rmCl('open')
    })
  }

  function search() {
    const searchForm = select('.search-form')
    const searchWrap = select('.search-wrap')
    const searchTrig = select('.search-trig', searchWrap)
    const searchInput = select('#search', searchWrap)

    if (searchInput && searchTrig && searchWrap) {
      searchTrig.addEventListener('click', () => {
        if (!searchWrap.classList.contains('open')) {
          if (window.innerWidth < 576) {
            app.mobileMenuCollapseInit.hide()
            setTimeout(() => {
              cl(searchWrap).addCl('open')
              searchInput.focus()
            }, 200)
          } else {
            cl(searchWrap).addCl('open')
            setTimeout(() => {
              searchInput.focus()
            }, 10)
          }
        } else if (searchInput && searchInput.value !== '') {
          if (window.innerWidth >= 576) {
            searchForm.submit();
          }
          cl(searchWrap).rmCl('open')
        } else {
          cl(searchWrap).rmCl('open')
        }
      })
    }
  }

  function windowClick(target) {
    if (!target.classList.contains('search-wrap') && !target.closest('.search-wrap')) {
      cl('.search-wrap').rmCl('open')
    }
  }

  function setLazy() {
    app.lazyLoadInstance = new LazyLoad()
  }


  function exclusiveToggle(){
    const toggleView = document.querySelector('a[data-reseller-only]');
    const hasResellerOnly = document.querySelector('.chairs-list-el.reseller');
    const displayClass = 'd-none';



    if (toggleView) {

      //we need to 'show' the reseller only button?
      if( hasResellerOnly ) {

        cl(toggleView).rmCl(displayClass);

        toggleView.addEventListener('click', () => {
          let showOnlyReseller = toggleView.getAttribute('data-reseller-only');

          //close any open spoilers
          app.spoilers2.closeAll();

          if(showOnlyReseller == 'true')
          {
            let chairs = select.all('.chairs-list-el:not(.reseller)');
            toggleView.setAttribute('data-reseller-only','false');

            chairs.forEach(chair => {
              cl(chair).addCl(displayClass);
            })

            toggleView.text = toggleView.getAttribute('data-text-show-all');
            app.chairList.resize();
          }
          else
          {
            let chairs = select.all('.chairs-list-el.'+displayClass);
            toggleView.setAttribute('data-reseller-only','true');

            chairs.forEach(chair => {
              cl(chair).rmCl(displayClass);
            })

            toggleView.text = toggleView.getAttribute('data-text-show-exclusive');
            app.chairList.resize();

          }


        })
      }
    }
  }


  function setChairSizes(chairs, oneRow = false) {
    const rows = [[]]
    let prew = null

    chairs.forEach((chair, i) => {
      cl(chair.wrap).rmCl('last');


      if(chair.wrap.classList.contains('d-none')){
        // toggled reseller only chair... so don't use as a calc.
       return;
      }
      else
      {
        const itemRect = chair.el.getBoundingClientRect();
        let row = prew && !oneRow ? (prew.top === itemRect.top ? prew.row : prew.row + 1) : 0

        const curr = {
          row: row,
          top: itemRect.top,
          height: chair.el.dataset.height ? Number(chair.el.dataset.height) : 0,
          width: chair.el.dataset.width ? Number(chair.el.dataset.width) : 0,
          mobileHeight: chair.mobileEl ? (chair.mobileEl.dataset.height ? Number(chair.mobileEl.dataset.height) : 0) : 0,
          mobileWidth: chair.mobileEl ? (chair.mobileEl.dataset.width ? Number(chair.mobileEl.dataset.width) : 0) : 0,
          index: i
        }

        if (!oneRow && prew && prew.row !== curr.row) {
          rows.push([curr])
        } else {
          rows[row].push(curr)
        }

        if (prew && prew.row !== curr.row) {
          cl(chairs[i-1].wrap).addCl('last')
        }

        prew = curr
      }
    })

    rows.forEach((row) => {
      const max = row.length ? row.reduce(function(p, c) {
        return (p.height > c.height) ? p : c
      }) : null
      const mobileMax = row.length ? row.reduce(function(p, c) {
        return (p.mobileHeight > c.mobileHeight) ? p : c
      }) : null
      row.forEach(el => {
        const chair = chairs[el.index]
        if (chair) {
          //chair.el.style.width = el.width+'px'
          // console.log('setting width');
          if (chair.title) chair.title.style.width = el.width+'px'
          chair.el.style.height = max.height+'px'
          if ( chair.mobileEl ) {
            chair.mobileEl.style.height = mobileMax.mobileHeight+'px'
          }
        }
      })
    })
  }

  function mapChairNodes(nodes) {
    return nodes.map(wrap => {
      const el = select('.chairs-list-image', wrap)
      const mobileEl = select('.mobile-chairs-list-image', wrap)
      const title = select('.chairs-list-title', wrap)
      const image = select('img', el)
      return { wrap, el, mobileEl, image, title }
    })
  }

  const chairList = function () {
    const chairs = mapChairNodes(select.all('.chairs-list-el.top-level'))
    const chairsInners = [];
    chairs.forEach(chair => {
      const chairsInner = mapChairNodes(Array.from(chair.wrap.querySelectorAll('.range-spoiler-inner .chairs-list-el')));
      chairsInners.push(chairsInner);
    })

    this.resize = function () {
      setChairSizes(chairs)
      chairsInners.forEach(chairsInner => {
        setChairSizes(chairsInner, true)
      })
    }

    this.resize()
  }

  // function initTooltip() {
  //   const tooltipElements = doc.querySelectorAll('[data-toggle="tooltip"]')
  //   Array.from( tooltipElements ).map(
  //     tE => {
  //       const tooltip = new Tooltip( tE, {
  //         placement: 'top'
  //       } )
  //       tE.addEventListener( 'mousemove', e => {
  //         const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //         tooltip.tooltip.style.left = e.clientX - 15 + 'px'
  //         tooltip.tooltip.style.top = scrollTop + e.clientY - 45 + 'px'
  //       })
  //       tE.addEventListener( 'mouseenter', e => {
  //         tooltip.show()
  //       })
  //       tE.addEventListener( 'mouseleave', e => {
  //         tooltip.hide()
  //       })
  //     }
  //   )
  // }

  const homeBlocks = function() {
    const firstTileBlock = doc.querySelector( '.home-blocks .home-block-tile-wrap:first-child' )
    const secondTileBlock = doc.querySelector( '.home-blocks .home-block-tile-wrap:nth-child(2)' )
    const thirdTileBlock = doc.querySelector( '.home-blocks .home-block-tile-wrap:nth-child(3)' )
    this.resize = function () {
      if (firstTileBlock) {
        if ( win.matchMedia('(min-width: 768px)').matches ) {
          firstTileBlock.querySelector( '.home-block-column' ).style.width = thirdTileBlock.clientWidth + 'px';
          secondTileBlock.querySelector( '.home-block-column' ).style.width = thirdTileBlock.clientWidth + 'px';
        } else {
          firstTileBlock.querySelector( '.home-block-column' ).style.width = '50%';
          secondTileBlock.querySelector( '.home-block-column' ).style.width = '50%';
        }
      }
    }
    this.resize()
  }

  doc.addEventListener('DOMContentLoaded', function () {
    FastClick.attach(document.body);
    cl('.curtain').rmCl('open');
    setAutocompletes();
    search();
    activenav();
    inputs();
    setLazy();
    //swipers(); - race condition, don't call from here. Called from setchairSwipper
    exclusiveToggle();

    app.spoilers = new Spoilers();
    app.spoilers2 = new Spoilers2('.chairs-list-section, .block-collapsibles');
    app.modals = new modals();
    app.chairList = new chairList();
    app.chairSwipper = new chairSwipper(); //acrtaully controls the range chair swipper
    app.homeBlocks = new homeBlocks();

    const test = select('.test-wrap')
    const testWrap = test ? test.getBoundingClientRect().width : 0
    const images = select.all('img', test)
    const together = images.reduce((a, b) => a + +b.dataset.width, 0)
    const scaleBy = testWrap / together
    images.forEach(omg => {
      omg.style.width = (Number(omg.dataset.width) * scaleBy) + 'px'
    })


    // win.addEventListener('scroll', throttle(function () {
    // }, 50));

    win.addEventListener('resize', throttle(function () {
      app.spoilers.resize();
      app.spoilers2.resize();
      app.chairList.resize();
      app.homeBlocks.resize();
    }, 300));

    win.addEventListener('resize', throttle(function () {
      //console.log('throttling')
      app.chairSwipper.resize();
    }, 1500));

    win.addEventListener('click', function (ev) {
      app.modals.close(ev.target);
      windowClick(ev.target)
    });

    const mobileMenu = doc.querySelector("#header-nav");
    if ( mobileMenu ) {
      app.mobileMenuCollapseInit = new Collapse(mobileMenu);
      mobileMenu.addEventListener('show.bs.collapse', (e) => {
        doc.body.classList.add( 'mobile-menu-open' )
      }, false)
      mobileMenu.addEventListener('hide.bs.collapse', (e) => {
        doc.body.classList.remove( 'mobile-menu-open' )
      }, false)
      const mobileMenuCollapses = mobileMenu.querySelectorAll('.mega-drop.collapse');
      Array.from(mobileMenuCollapses).forEach((collapse) => 
        new Collapse(
          collapse,
          {
            parent: mobileMenu
          }
        )
      )
      const situationSubMenu = doc.querySelector("#mega-drop-by-situation");
      const situationSubMenuCollapses = situationSubMenu.querySelectorAll('.mega-cat > .collapse');
      Array.from(situationSubMenuCollapses).forEach((collapse) => 
        new Collapse(
          collapse,
          {
            parent: situationSubMenu
          }
        )
      )
      const typeSubMenu = doc.querySelector("#mega-drop-by-type");
      const typeSubMenuCollapses = typeSubMenu.querySelectorAll('.mega-cat > .collapse');
      Array.from(typeSubMenuCollapses).forEach((collapse) => 
        new Collapse(
          collapse,
          {
            parent: typeSubMenu
          }
        )
      )

      const ergonomicsSubMenu = doc.querySelector("#mega-drop-ergonomics");
      const ergonomicsSubMenuCollapses = ergonomicsSubMenu.querySelectorAll('.mega-cat > .collapse');
      Array.from(ergonomicsSubMenuCollapses).forEach((collapse) => 
        new Collapse(
          collapse,
          {
            parent: ergonomicsSubMenu
          }
        )
      )

      const referenceSubMenu = doc.querySelector("#mega-drop-reference");
      const referenceSubMenuCollapses = referenceSubMenu.querySelectorAll('.mega-cat > .collapse');
      Array.from(referenceSubMenuCollapses).forEach((collapse) => 
        new Collapse(
          collapse,
          {
            parent: referenceSubMenu
          }
        )
      )

      const aboutUsSubMenu = doc.querySelector("#mega-drop-about-us");
      const aboutUsSubMenuCollapses = aboutUsSubMenu.querySelectorAll('.mega-cat > .collapse');
      Array.from(aboutUsSubMenuCollapses).forEach((collapse) => 
        new Collapse(
          collapse,
          {
            parent: aboutUsSubMenu
          }
        )
      )
    }
  })
})(document, window);

import select from 'dom-select'
import { classy as cl } from './classy'
// import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min'

const Spoilers2 = function (wrapSelector) {
  const wait = false
  const openTime = 400

  const spoilers = []
  let activeId = null

  let prevH = null

  function getCoords (elem) {
    const box = elem.getBoundingClientRect()

    const body = document.body
    const docEl = document.documentElement

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    const clientTop = docEl.clientTop || body.clientTop || 0
    const clientLeft = docEl.clientLeft || body.clientLeft || 0

    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return { top: Math.round(top), left: Math.round(left) }
  }

  function smooth (position) {
    // const scroll = new SmoothScroll()
    // setTimeout(() => {
    //   scroll.animateScroll(position, false, {
    //     offset: 200,
    //     durationMin: 300
    //   })
    // }, openTime)
  }

  function scrollInPlace (previous, current) {
    const offset = window.innerWidth < 768 ? 100 : 200
    if (previous && current) {
      if (previous.y < current.y) {
        smooth((current.y - previous.h) - offset)
      } else {
        smooth(current.y - offset)
      }
    }
  }

  function setSpoilerPosition(s) {
    const topEl = s.spaceEl.getBoundingClientRect().top + document.documentElement.scrollTop
    const topSection = s.section.getBoundingClientRect().top + document.documentElement.scrollTop

    s.spaceEl.style.height = (height(s.content) + 30) + 'px'
    s.content.style.top = (topEl - topSection) + 'px'
  }

  function open (s, noscroll) {
    if (!noscroll) {
      smooth(s.click)
    }
    s.isOpen = true
    s.content.style.height = height(s.content) + 'px'
    if (s.spaceEl) {
      setSpoilerPosition(s)
    }
    setTimeout(function () {
      s.content.style.height = ''
    }, openTime)
    if (s.click) cl(s.click).addCl('active')
    if (s.content) cl(s.content).addCl('active')
  }

  function close (s) {
    //console.log('closing spoilers2')
    if (s.isOpen) {
      s.isOpen = false
      prevH = {
        h: height(s.content),
        y: getCoords(s.click).top
      }
      s.content.style.height = height(s.content) + 'px'
      cl(s.click).rmCl('active')
      setTimeout(function () {
        cl(s.content).rmCl('active')
        if (s.spaceEl) {
          s.spaceEl.style.height = 0
        }
      }, 10)
      setTimeout(function () {
        s.content.style.height = ''
      }, wait ? openTime : 10)
    }
  }

  function click (ev, parentId) {
    const target = ev.target.closest('[data-accordion]')
    const id = target ? target.dataset.accordion : false

    if (!id) {
      return false
    }

    const parentSpoiler = parentId ? spoilers.find(s => s.id === parentId) : null
    const sp = parentSpoiler ? parentSpoiler.children : spoilers
    const spoiler = sp.find(s => s.id === id)

    if (!spoiler) {
      return false
    }

    if (spoiler.isOpen) {
      close(spoiler)
      spoiler.children.forEach(close)
      activeId = null
    } else {
      if (activeId) {
        setTimeout(() => {
          activeId = id
          open(spoiler)
        }, openTime)
      } else {
        activeId = id
        open(spoiler)
      }

      spoilers.forEach(s => {
        s.children.forEach(s => {
          if (s.id !== id) close(s)
        })
        const childOpen = s.children && s.children.some(s => s.isOpen)
        if (!childOpen && s.id !== id) {
          close(s)
        }
      })
    }

    scrollInPlace(prevH, {
      h: height(spoiler.content),
      y: getCoords(spoiler.click).top
    })
  }

  function height (content) {
    return content && content.children[0] ? content.children[0].getBoundingClientRect().height : false
  }

  select.all(wrapSelector).forEach(section => {
    const spoilerClicks = select.all('[data-accordion]:not([data-accordion-child])', section)
    spoilerClicks.forEach(function (spoilerClick) {
      const id = spoilerClick.dataset.accordion
      const content = select('#' + id)
      const isOpen = spoilerClick.classList.contains('active')
      const spaceEl = content && typeof content.dataset.accordionSpace !== 'undefined' && content.nextElementSibling ? content.nextElementSibling : null

      const children = select.all('[data-accordion]', content).map(spoiler => {
        const parentId = spoilerClick.dataset.accordion
        const id = spoiler.dataset.accordion
        spoiler.addEventListener('click', function (ev) {
          click(ev, parentId)
        })
        return {
          id: id,
          click: spoiler,
          content: select('#' + id, content),
          isOpen: false,
          children: null
        }
      })

      const spoiler = {
        id: id,
        click: spoilerClick,
        content: content,
        isOpen: false,
        children: children,
        spaceEl,
        section
      }

      if (isOpen && content) {
        activeId = id
        open(spoiler, true)
      }

      spoilers.push(spoiler)

      spoilerClick.addEventListener('click', click)
    })
  })

  this.closeAll = function () {
    spoilers.forEach(close)
  }

  this.resize = function () {
    spoilers.forEach(s => {
      if (s.spaceEl && s.isOpen) {
        setSpoilerPosition(s)
      }
    })
  }
}

export default Spoilers2

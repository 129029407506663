import select from "dom-select";
import { classy as cl } from "./classy";
import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.min'

function smooth (position) {
  const scroll = new SmoothScroll()
  setTimeout(() => {
    scroll.animateScroll(position, false, {
      offset: 100,
      durationMin: 300,
      updateURL: false
    })
  }, 300)
}


const Spoilers = function(parent) {
  parent = typeof parent === 'string' ? select(parent) : parent ? parent : document.body;

  function open(s) {
    s.isOpen = true;
    // setTimeout(function(){
      if (s.click) cl(s.click).addCl('active');
      if (s.content) cl(s.content).addCl('active');
    // },700);
  }

  function close(s) {    
    s.isOpen = false;
    cl(s.click).rmCl('active');
    cl(s.content).rmCl('active');
  }

  function openCloseOther (index, spoilerIndex, spoilers) {
    spoilers.forEach((spoiler, i) => {
      if (i !== spoilerIndex && spoiler.tabs.length) {
        spoiler.tabs.forEach((tab, tabInd) => {
          if (tabInd !== index) {
            //console.log('openCloseOther');
            close(tab)
          } else {
            tab.isOpen = true
          }
        })
      }
    })
  }

  var firstTabHeight = false;

  function height(content, tabsH, wrap ) {
   //console.log('pre-timneout before setting height, so hopfully all the content loads and we get the correct height!')
    setTimeout(function(){
      var tabs = tabsH ? tabsH.getBoundingClientRect().height : 0
      var h = content && content.children[0] ? content.children[0].getBoundingClientRect().height + tabs + 10 : false

      // get the default min-height.
      if (firstTabHeight == false) firstTabHeight = h;
      
      // ensure we're at least as tall as the first tab.
      if( h < firstTabHeight ) h = firstTabHeight;

      if (h && wrap) {
        wrap.style.cssText = 'height:'+h+'px';
        //console.log('setting height of tab to '+h+'px')
      }
    },700)
  }


  var spoilerClicks = select.all('[data-spoiler]', parent);
  var wrap = select('.product-spoilers');
  var spoilers = [];
  var activeIndex = null;
  var hash = window.location.hash;

  spoilerClicks.forEach(function (spoilerClick, i) {
    var content = select('#'+spoilerClick.dataset.spoiler, parent);
    var tabs = [];
    var activeTabIndex = null;
    var isOpen = spoilerClick.classList.contains('active');
    var tabsWrap = select('.tab-navs', content);
    if (isOpen && content) {
      activeIndex = i;
      height(content, tabsWrap, wrap)
    }

    select.all('[data-tab]', content).forEach(function (tab, ind) {
      var tabContent = select('#'+tab.dataset.tab, parent);
      var isTabOpen = tab.classList.contains('active');

      const tabEl = {
        click: tab,
        content: tabContent,
        isOpen: isTabOpen,
      }

      tabs.push(tabEl);

      if (isTabOpen) {
        activeTabIndex = ind
        if (isOpen) {
          height(tabEl.content, tabsWrap, wrap)
        }

      }

      tab.addEventListener('click', function () {
        var activeTab = tabs[ind];
        if (!activeTab.content) console.error('No tab content found for '+tab.dataset.tab);
        if (activeTab.content) {
          tabs.forEach(t => (close(t)))
          openCloseOther(ind, activeIndex, spoilers)
          activeTabIndex = ind;
          open(activeTab);
          if ( activeTab ) {
            height(activeTab.content, tabsWrap, wrap)
          }
        }
      });
    });

    spoilers.push({
      click: spoilerClick,
      content: content,
      isOpen: isOpen,
      tabs: tabs,
      tabsWrap
    });

    spoilerClick.addEventListener('click', function () {
      var spoiler = spoilers[i];
      //console.log('spoilerClick')
      if (spoiler.isOpen) {
        //don't want to close the only open tab box. One should be open at all times.
        return;
        close(spoilers[i]);
        cl(wrap).rmCl('active');
        activeIndex = null;
        wrap.style.cssText = 'height: 0';
      }
      else {
        if (activeIndex == null) {
          spoilers.forEach(close);
        }
        if (activeIndex !== null && activeIndex !== i) close(spoilers[activeIndex]);
        if (!content) console.error('No spoiler content found for '+spoilerClick.dataset.spoiler);
        doOpen(spoiler, i);
      }
    });
  });

  function cloaseAll() {
    spoilers.forEach(close);
    activeIndex = null;
    wrap.style.cssText = 'height: 0';
    cl(wrap).rmCl('active');
  }

  this.closeAll = cloaseAll;

  this.resize = function () {
    if (activeIndex !== null) {
      var activeSpoiler = spoilers[activeIndex]
      var activeTab = activeSpoiler ? spoilers[activeIndex].tabs.find((tab) => tab.isOpen) : null;
      if (activeTab && activeSpoiler) {
        height(activeTab.content, activeSpoiler.tabsWrap, wrap)
      }
    }
  };

  select.all('.close-spoilers').forEach(function (close) {
    close.addEventListener('click', cloaseAll)
  });

  function doOpen(spoiler, i) {
    // console.log('spoiler opening')
    if (spoiler && spoiler.content) {
      activeIndex = i;
      open(spoiler);
      cl(wrap).addCl('active');

      //1: if you want the currently open tab to open on for the new chair
      var activeTab = spoiler.tabs.find((tab) => tab.isOpen);

      //2: But we don't - so close any active tab if open
      if(activeTab){
        //console.log('close active tab');
        close(activeTab)
      }

      //3: so set it to always open the first tab.
      activeTab = spoiler.tabs[0];
      if (activeTab && wrap) {
        open(activeTab)
        height(activeTab.content, spoiler.tabsWrap, wrap)
        // cl(wrap).addCl('active');
        // open(spoiler);
      }
    }
  }

  if (hash) {
    var spoilerIndex = spoilers.findIndex(s => hash.indexOf(`#${s.content.id}`) != -1 ? 1 : 0)
    if (spoilerIndex > -1) doOpen(spoilers[spoilerIndex], spoilerIndex);
    const rangeSwiper = select('.range-swiper')
    if (rangeSwiper) {
     // smooth(rangeSwiper)
    }
  }
}

export default Spoilers;
